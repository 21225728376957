import apiClient from "./http-common";

class ExchangeConnectionService {
  getAll() {
    return apiClient.get("/exchange-connection");
  }

  create(apiKey) {
    return apiClient.post("/exchange-connection", apiKey)
  }
  test(apiKey) {
    return apiClient.post("/exchange-connection/test", apiKey)
  }
}

export default new ExchangeConnectionService();
