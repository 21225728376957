<template>
  <div class="dropdown ms-1 topbar-head-dropdown header-item">
    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img id="header-lang-img" :src="imagePath" alt="Header Language" height="20" class="rounded" />
    </button>
    <div class="dropdown-menu dropdown-menu-end">
      <!-- item-->
      <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
         @click="setLanguage(entry.language, entry.title, entry.flag)"
         :class="{ active: currentLocale === entry.language }" class="dropdown-item notify-item language py-2"
         data-lang="en" title="English">
        <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18" width="18" />
        <span class="align-middle">{{ entry.title }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import {localeComputed} from "@/state/helpers";
import i18n from "@/i18n";

export default {
  name: 'LanguageSelector',
  computed: {
    ...localeComputed,
  },
  watch: {
    currentLocale() {
      const images = require.context('@/assets/images/flags/', false, /\.svg$/);
      this.imagePath = images('./' + this.currentLocale + ".svg")
    },
  },
  mounted() {
    const images = require.context('@/assets/images/flags/', false, /\.svg$/);
    this.imagePath = images('./' + this.currentLocale + ".svg")
  },
  methods: {
    setLanguage(locale) {
      this.$store.dispatch("locale/set", locale)
      i18n.global.locale = locale;
    },
  },
  data() {
    return {
      imagePath: '',
      languages: [
        {
          flag: require("@/assets/images/flags/en.svg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/ru.svg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/pl.svg"),
          language: "pl",
          title: "Polski",
        },
      ],
    }
  },
}
</script>
