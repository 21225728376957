import apiClient from "./http-common";

class BotService {
  fetchAll() {
    return apiClient.get("/bot");
  }
  create(data) {
    return apiClient.post("/bot", data);
  }
  findOne(id) {
    return apiClient.get("/bot/" + id);
  }
  update(id, title, orderAmount, activeDeals, safetyOrderAmount, blacklist) {
    return apiClient.put("/bot/" + id, {
      "title": title,
      "active_deals": activeDeals,
      "first_order_amount": orderAmount,
      "safety_order_amount": safetyOrderAmount,
      "blacklisted_pairs": blacklist
    });
  }
}

export default new BotService();
